import { coreBlue } from '../colorPalette';

export default {
  MuiRadio: {
    root: {
      background: 'none',
      '&$checked': {
        '&:hover': {
          background: 'none',
        },
        color: coreBlue,
      },
      '&:hover': {
        background: 'none',
      },
    },
  },
};
