import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import MuiButton from './overrides/MuiButton';
import MuiCheckbox from './overrides/MuiCheckbox';
import MuiInput from './overrides/MuiInput';
import MuiRadio from './overrides/MuiRadio';
import MuiSwitch from './overrides/MuiSwitch';
import MuiFilledInput from './overrides/MuiFilledInput';
import MuiSelect from './overrides/MuiSelect';
import responsiveFonts from './responsiveFonts';
import MuiMenuItem from './overrides/MuiMenuItem';
import MuiExpansionPanel from './overrides/MuiExpansionPanel';
import MuiIconButton from './overrides/MuiIconButton';

const theme = createMuiTheme({
  ...palette,
  spacing: 4,
  overrides: {
    ...MuiButton,
    ...MuiCheckbox,
    ...MuiInput,
    ...MuiRadio,
    ...MuiSwitch,
    ...MuiFilledInput,
    ...MuiSelect,
    ...MuiMenuItem,
    ...MuiExpansionPanel,
    ...MuiIconButton,
  },
});

export default responsiveFonts(theme);
