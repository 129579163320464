import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles/';
import PropTypes from 'prop-types';
import theme from './src/themeprovider/ccmTheme/index';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
