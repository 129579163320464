import { coreBlue } from '../colorPalette';

export default {
  MuiSwitch: {
    root: {
      '& $switchBase': {
        color: '#F1F1F1',
        '&:hover': {
          background: 'none',
        },
      },
      '& $checked': {
        '&:hover': {
          background: 'none',
        },
        '& $track': {
          backgroundColor: coreBlue,
        },
        '& &track + &disabled': {
          backgroundColor: coreBlue,
        },
      },
    },
  },
};
