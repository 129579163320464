import { coreYellow } from '../colorPalette';

export default {
  MuiMenuItem: {
    root: {
      '&:hover': {
        backgroundColor: coreYellow,
      },
    },
  },
};
