export default (theme) => ({
  ...theme,
  typography: {
    ...theme.typography,
    h1: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '43.2px',
      marginBottom: '1.45rem',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: '30px',
      marginBottom: '1.25rem',
    },
    h3: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '24px',
      marginBottom: '1.25rem',
    },
    h4: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '20px',
      marginBottom: '0.75rem',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 'normal',
    },
    body2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '24px',
      fontWeight: 'normal',
    },
    button1: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      letterSpacing: '0.0357143em',
    },
    button2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.0357143em',
    },
    input: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 'normal',
    },
  },
});
