export default {
  MuiExpansionPanel: {
    root: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      '&$expanded': {
        margin: 0,
      },
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      height: '55px',
      minHeight: '55px',
      '&$expanded': {
        minHeight: '55px',
      },
    },
  },
};
