import { coreBlue, coreRed } from '../colorPalette';

export default {
  MuiInput: {
    root: {
      '&$focused': {
        color: coreBlue,
      },
      '&$underline:after': {
        borderBottom: 'none',
      },
      '&$underline:before': {
        borderBottom: 'none',
      },
      '&:hover:before': {
        borderBottom: 'none',
      },
      borderBottom: `2px solid ${coreBlue}`,
      '&.Mui-error': {
        borderBottom: `2px solid ${coreRed}`,
      },
      '&.MuiFormHelperText-root': {
        borderBottom: `2px solid ${coreRed}`,
      },
    },
    input: {
      '&::placeholder': {
        color: 'red',
      },
    },
    underline: {
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:before': {
        borderBottom: 'none',
      },
      '&:hover:not($disabled):before': {
        borderBottom: 'none',
      },
    },
  },
};
