import { coreBlue } from '../colorPalette';

export default {
  MuiSelect: {
    root: {
      '&:hover': {
        borderBottom: 'none',
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'none',
      },
    },
    icon: {
      color: coreBlue,
    },
  },
};
