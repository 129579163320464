import { coreBlue } from '../colorPalette';

export default {
  MuiFilledInput: {
    root: {
      fontSize: '16px',
      backgroundColor: 'rgba(51, 51, 51, 0.06)',
      borderBottom: `2px solid ${coreBlue}`,
      height: '55px',
    },
    input: {
      padding: '0 0 0 17px',
      height: '55px',
      '&::placeholder': {
        fontSize: '16px',
      },
    },
    underline: {
      '&:before, &:after, &:hover:before': {
        borderBottom: 'none',
      },
    },
  },
};
