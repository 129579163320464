import { coreWhite } from '../colorPalette';

export default {
  MuiButton: {
    root: {
      borderRadius: '50px',
      '&:active': {
        boxShadow: '0px 4px 20px rgba(41, 55, 69, 0.55)',
      },
      padding: '12px 20px',
    },
    label: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      fontSize: '18px',
      color: coreWhite,
    },
  },
};
